// import { StringByString } from '@/types/index-signatures';

const sizes = {
  xxs: 'w-3 h-3',
  xs: 'w-4 h-4',
  sm: 'w-5 h-5',
  md: 'w-6 h-6',
  lg: 'w-7 h-7',
  xl: 'w-10 h-10',
  xxl: 'w-16 h-16',
} as any;

const setSize = (size: any): string => {
  return sizes[size];
};

export { sizes, setSize };
