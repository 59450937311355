<template>
  <component :is="currentIcon"></component>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue';
import { setSize } from './icon-sizes';

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
  },
  computed: {
    fileName() {
      const capitalizeFirst = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
      return this.icon
        .split('-')
        .map(string => capitalizeFirst(string))
        .join('-');
    },
    currentIcon() {
      return defineAsyncComponent(
        () => import(`../../../assets/svg/pictograms/${this.fileName}.svg`)
      );
    },
    sizeClasses() {
      return setSize(this.$props.size);
    },
  },
});
</script>
