<template>
  <header class="flex items-center justify-between px-4 pt-6 pb-8 md:pt-10">
    <h1 class="sr-only">FirstVet skadenummer</h1>
    <div class="">
      <button
        role="link"
        aria-label="Go back"
        @click="goBack"
        class="rounded-xl text-stone-500 pr-2 transition-transform duration-150 ease-in-out hover:scale-125 hover:transform focus:outline-none focus:ring-2 focus:ring-primary-light"
        v-show="!!route.meta.showBackButton"
      >
        <FvIcon icon="arrow-left"></FvIcon>
      </button>
    </div>

    <img class="h-7 sm:h-7" :src="LogoSmall" alt="Firstvet logo" />
    <div></div>
  </header>
</template>

<script setup lang="ts">
import LogoSmall from '@/assets/svg/firstvet_logo.svg?url';
import FvIcon from './UI/icons/FvIvon.vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();

const goBack = () => {
  router.back();
};
</script>
