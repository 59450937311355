import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'start',
      component: () => import('@/views/Start.vue'),
      meta: {
        showBackButton: false,
      },
    },
    {
      path: '/confirm-mobile-phone',
      name: 'confirm-mobile-phone',
      component: () => import('@/views/ConfirmMobilePhone.vue'),
      meta: {
        showBackButton: true,
      },
    },
    {
      path: '/success',
      name: 'success',
      component: () => import('@/views/Success.vue'),
      meta: {
        showBackButton: false,
      },
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { el: '#main', top: 0, behavior: 'smooth' };
  },
});

export default router;
