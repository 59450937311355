import { createApp } from 'vue'
import { createPinia } from 'pinia';

import App from './App.vue'
import router from './router'

import VueCountdown from '@chenfengyuan/vue-countdown';

import '@/assets/index.postcss';

const pinia = createPinia();

const app = createApp(App)

app.component(VueCountdown.name, VueCountdown);

app.use(router).use(pinia).mount('#app')
